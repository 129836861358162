import _ from "lodash";
import { TFunction } from "i18next";
import { prefixTestnet, remove0xPrefix } from "src/utils/string";
import { env, isTestnetAndDevelopment, ReactEnv } from "src/libs/env";
import { BTC_DECIMAL, ETH_AND_KLAY_DECIMAL } from "src/utils/number";
import { translate } from "src/locales";
import { Blockchain } from "src/__generate__/api";

const makeEthereumAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://ropsten.etherscan.io/address/${address}`;
  }
  return `https://etherscan.io/address/${address}`;
};

const makeEthereumTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://ropsten.etherscan.io/tx/${txHash}`;
  }
  return `https://etherscan.io/tx/${txHash}`;
};

const makeKlaytnTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://baobab.scope.klaytn.com/tx/${txHash}`;
  }
  return `https://scope.klaytn.com/tx/${txHash}`;
};

const makeKlaytnAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://baobab.scope.klaytn.com/account/${address}`;
  }
  return `https://scope.klaytn.com/account/${address}`;
};

const makePolygonTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://mumbai.polygonscan.com/tx/${txHash}`;
  }
  return `https://polygonscan.com/tx/${txHash}`;
};

const makePolygonAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://mumbai.polygonscan.com/address/${address}`;
  }
  return `https://polygonscan.com/address/${address}`;
};

const makeAmoyTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://amoy.polygonscan.com/tx/${txHash}`;
  }
  return `https://polygonscan.com/tx/${txHash}`;
};

const makeAmoyAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://amoy.polygonscan.com/address/${address}`;
  }
  return `https://polygonscan.com/address/${address}`;
};

const makeBTCTxHashLink = (txHash: string) => {
  const removed0xTxHash = remove0xPrefix(txHash);
  if (isTestnetAndDevelopment) {
    return `https://blockstream.info/testnet/tx/${removed0xTxHash}`;
  }
  return `https://blockstream.info/tx/${removed0xTxHash}`;
};

const makeOverTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://dolphin.view.over.network/tx/${txHash}`;
  }
  return `https://view.over.network/tx/${txHash}`;
};

const makeOverAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://dolphin.view.over.network/address/${address}`;
  }
  return `https://view.over.network/address/${address}`;
};

const makeBscTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://bscscan.com/tx/${txHash}`;
  }
  return `https://bscscan.com/tx/${txHash}`;
};

const makeBscAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://bscscan.com/address/${address}`;
  }
  return `https://bscscan.com/address/${address}`;
};

const makeWemixTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://testnet.wemixscan.com/tx/${txHash}`;
  }
  return `https://wemixscan.com/tx/${txHash}`;
};

const makeWemixAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://testnet.wemixscan.com/address/${address}`;
  }
  return `https://wemixscan.com/address/${address}`;
};

const makeXplaTxHashLink = (txHash: string) => {
  if (isTestnetAndDevelopment) {
    return `https://explorer.xpla.io/mainnet/tx/${txHash}`;
  }
  return `https://explorer.xpla.io/testnet/tx/${txHash}`;
};

const makeXplaAddressLink = (address: string) => {
  if (isTestnetAndDevelopment) {
    return `https://explorer.xpla.io/mainnet/address/${address}`;
  }
  return `https://explorer.xpla.io/testnet/address/${address}`;
};

const blockchainItems: Record<
  Blockchain,
  {
    unit: string;
    decimals: number;
    blockchain: Blockchain;
    makeTxHashLink: (txHash: string) => string;
    makeAddressLink: (address: string) => string;
  }
> = {
  [Blockchain.Ethereum]: {
    blockchain: Blockchain.Ethereum,
    unit: "ETH",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeEthereumTxHashLink,
    makeAddressLink: makeEthereumAddressLink,
  },
  [Blockchain.EthereumV2]: {
    blockchain: Blockchain.EthereumV2,
    unit: "ETH",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeEthereumTxHashLink,
    makeAddressLink: makeEthereumAddressLink,
  },
  [Blockchain.Klaytn]: {
    blockchain: Blockchain.Klaytn,
    unit: "KLAY",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeKlaytnTxHashLink,
    makeAddressLink: makeKlaytnAddressLink,
  },
  [Blockchain.KlaytnV2]: {
    blockchain: Blockchain.KlaytnV2,
    unit: "KLAY",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeKlaytnTxHashLink,
    makeAddressLink: makeKlaytnAddressLink,
  },
  [Blockchain.Baobab]: {
    blockchain: Blockchain.Baobab,
    unit: "KLAY",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeKlaytnTxHashLink,
    makeAddressLink: makeKlaytnAddressLink,
  },
  [Blockchain.Polygon]: {
    blockchain: Blockchain.Polygon,
    unit: "MATIC",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makePolygonTxHashLink,
    makeAddressLink: makePolygonAddressLink,
  },
  [Blockchain.Bitcoin]: {
    blockchain: Blockchain.Bitcoin,
    unit: "BTC",
    decimals: BTC_DECIMAL,
    makeTxHashLink: makeBTCTxHashLink,
    makeAddressLink: _.identity,
  },
  [Blockchain.Amoy]: {
    blockchain: Blockchain.Amoy,
    unit: "MATIC",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeAmoyTxHashLink,
    makeAddressLink: makeAmoyAddressLink,
  },
  [Blockchain.PolygonV2]: {
    blockchain: Blockchain.PolygonV2,
    unit: "MATIC",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makePolygonTxHashLink,
    makeAddressLink: makePolygonAddressLink,
  },
  [Blockchain.OverTestnet]: {
    blockchain: Blockchain.OverTestnet,
    unit: "OVER",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeOverTxHashLink,
    makeAddressLink: makeOverAddressLink,
  },
  [Blockchain.Over]: {
    blockchain: Blockchain.Over,
    unit: "OVER",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeOverTxHashLink,
    makeAddressLink: makeOverAddressLink,
  },
  [Blockchain.Bsc]: {
    blockchain: Blockchain.Bsc,
    unit: "BNB",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeBscTxHashLink,
    makeAddressLink: makeBscAddressLink,
  },
  [Blockchain.WemixTestnet]: {
    blockchain: Blockchain.WemixTestnet,
    unit: "WEMIX",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeWemixTxHashLink,
    makeAddressLink: makeWemixAddressLink,
  },
  [Blockchain.XplaTestnet]: {
    blockchain: Blockchain.XplaTestnet,
    unit: "XPLA",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeXplaTxHashLink,
    makeAddressLink: makeXplaAddressLink,
  },
  [Blockchain.Xpla]: {
    blockchain: Blockchain.Xpla,
    unit: "XPLA",
    decimals: ETH_AND_KLAY_DECIMAL,
    makeTxHashLink: makeXplaTxHashLink,
    makeAddressLink: makeXplaAddressLink,
  },
};

export const getBlockchainItem = (blockchain: Blockchain) => {
  return blockchainItems[blockchain];
};

export const getBlockchainItemBy: (
  blockchain: Blockchain,
  t: TFunction,
) => {
  label: string;
  noPrefixLabel: string;
  unit: string;
  decimals: number;
  blockchain: Blockchain;
  makeTxHashLink: (txHash: string) => string;
  makeAddressLink: (address: string) => string;
} = (blockchain: Blockchain, t: TFunction) => {
  const byBlockchain: Record<
    Blockchain,
    {
      label: string;
      noPrefixLabel: string;
      unit: string;
      decimals: number;
      blockchain: Blockchain;
      makeTxHashLink: (txHash: string) => string;
      makeAddressLink: (address: string) => string;
    }
  > = {
    [Blockchain.Ethereum]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "ethereum_deprecated"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "ethereum_deprecated"],
        t,
      ),
      ...blockchainItems[Blockchain.Ethereum],
    },
    [Blockchain.EthereumV2]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "ethereum"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "ethereum"],
        t,
      ),
      ...blockchainItems[Blockchain.EthereumV2],
    },
    [Blockchain.Klaytn]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn_deprecated"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn_deprecated"],
        t,
      ),
      ...blockchainItems[Blockchain.Klaytn],
    },
    [Blockchain.KlaytnV2]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn"],
        t,
      ),
      ...blockchainItems[Blockchain.KlaytnV2],
    },
    [Blockchain.Baobab]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "klaytn"],
        t,
      ),
      ...blockchainItems[Blockchain.Baobab],
    },
    [Blockchain.Polygon]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon_deprecated"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon_deprecated"],
        t,
      ),
      ...blockchainItems[Blockchain.Polygon],
    },
    [Blockchain.Bitcoin]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "bitcoin"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "bitcoin"],
        t,
      ),
      ...blockchainItems[Blockchain.Bitcoin],
    },
    [Blockchain.Amoy]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon"],
        t,
      ),
      ...blockchainItems[Blockchain.Amoy],
    },

    [Blockchain.PolygonV2]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "polygon"],
        t,
      ),
      ...blockchainItems[Blockchain.PolygonV2],
    },
    [Blockchain.OverTestnet]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "overProtocol"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "overProtocol"],
        t,
      ),
      ...blockchainItems[Blockchain.OverTestnet],
    },
    [Blockchain.Over]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "overProtocol"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "overProtocol"],
        t,
      ),
      ...blockchainItems[Blockchain.Over],
    },
    [Blockchain.Bsc]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "bsc"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "bsc"],
        t,
      ),
      ...blockchainItems[Blockchain.Bsc],
    },
    [Blockchain.WemixTestnet]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "wemix"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "wemix"],
        t,
      ),
      ...blockchainItems[Blockchain.WemixTestnet],
    },
    [Blockchain.XplaTestnet]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "xpla"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "xpla"],
        t,
      ),
      ...blockchainItems[Blockchain.XplaTestnet],
    },
    [Blockchain.Xpla]: {
      label: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "xpla"],
        t,
      ),
      noPrefixLabel: translate(
        ["utils", "blockchain", "getBlockchainItemBy", "xpla"],
        t,
      ),
      ...blockchainItems[Blockchain.Xpla],
    },
  };
  return byBlockchain[blockchain];
};

export const isIgnoreBlockchainType = (blockchain: Blockchain) => {
  return [Blockchain.Bitcoin].includes(blockchain);
};

export const toBlockchainUnit = (blockchain: Blockchain, t: TFunction) => {
  const blockchainItem = getBlockchainItemBy(blockchain, t);
  return blockchainItem.unit;
};

export const confirmCountText = ((reactEnv: ReactEnv) => {
  const confirmCountByEnv: Record<ReactEnv, Record<Blockchain, string>> = {
    local: {
      [Blockchain.Ethereum]: "2",
      [Blockchain.EthereumV2]: "2",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "1",
      [Blockchain.Bitcoin]: "2",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "1",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    development: {
      [Blockchain.Ethereum]: "2",
      [Blockchain.EthereumV2]: "2",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "1",
      [Blockchain.Bitcoin]: "2",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "1",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    testnet: {
      [Blockchain.Ethereum]: "2",
      [Blockchain.EthereumV2]: "2",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "1",
      [Blockchain.Bitcoin]: "2",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "1",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    ["testnet-staging"]: {
      [Blockchain.Ethereum]: "2",
      [Blockchain.EthereumV2]: "2",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "1",
      [Blockchain.Bitcoin]: "2",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "1",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    ["intranet-testnet"]: {
      [Blockchain.Ethereum]: "2",
      [Blockchain.EthereumV2]: "2",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "1",
      [Blockchain.Bitcoin]: "2",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "1",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    production: {
      [Blockchain.Ethereum]: "96",
      [Blockchain.EthereumV2]: "96",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "30",
      [Blockchain.Bitcoin]: "6",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "30",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
    ["production-staging"]: {
      [Blockchain.Ethereum]: "96",
      [Blockchain.EthereumV2]: "96",
      [Blockchain.Klaytn]: "1",
      [Blockchain.KlaytnV2]: "1",
      [Blockchain.Baobab]: "1",
      [Blockchain.Polygon]: "30",
      [Blockchain.Bitcoin]: "6",
      [Blockchain.Amoy]: "1",
      [Blockchain.PolygonV2]: "30",
      [Blockchain.OverTestnet]: "1",
      [Blockchain.Over]: "1",
      [Blockchain.Bsc]: "2.5",
      [Blockchain.WemixTestnet]: "1",
      [Blockchain.XplaTestnet]: "6",
      [Blockchain.Xpla]: "6",
    },
  };
  return (blockchain: Blockchain) => confirmCountByEnv[reactEnv][blockchain];
})(env.REACT_ENV);

export const makeDepositWarnings = (
  blockchain: Blockchain,
  t: TFunction,
  isTestnet: boolean,
) => {
  const { label: blockchainLabel, unit } = getBlockchainItemBy(blockchain, t);
  const byBlockchain: Record<Blockchain, string[]> = {
    [Blockchain.Ethereum]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line2"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line3"],
        t,
      ),
    ],
    [Blockchain.EthereumV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line2"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line3"],
        t,
      ),
    ],
    [Blockchain.Klaytn]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.KlaytnV2]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Baobab]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Polygon]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Bitcoin]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "bitcoinWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "bitcoinWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "bitcoinWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Amoy]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.PolygonV2]: [
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line1",
        ],
        t,
      ),
      translate(
        [
          "utils",
          "blockchain",
          "makeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.OverTestnet]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "overWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "overWarning", "line2"],
        t,
      ),
    ],
    [Blockchain.Over]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "overWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "overWarning", "line2"],
        t,
      ),
    ],
    [Blockchain.Bsc]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line2"],
        t,
      ),
    ],
    [Blockchain.WemixTestnet]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "wemixWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "wemixWarning", "line2"],
        t,
      ),
    ],
    [Blockchain.XplaTestnet]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "xplaWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "xplaWarning", "line2"],
        t,
      ),
    ],
    [Blockchain.Xpla]: [
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "xplaWarning", "line1"],
        t,
      ),
      translate(
        ["utils", "blockchain", "makeDepositWarnings", "xplaWarning", "line2"],
        t,
      ),
    ],
  };
  return [
    `${translate(
      [
        "utils",
        "blockchain",
        "makeGasFeeDepositWarnings",
        "ethWarning",
        "line1Prefix",
      ],
      t,
    )}${prefixTestnet(blockchainLabel, isTestnet)}${
      isIgnoreBlockchainType(blockchain)
        ? ``
        : `, ${prefixTestnet(unit, isTestnet)}${translate(
            ["utils", "blockchain", "makeDepositWarnings", "common", "token"],
            t,
          )}`
    }${translate(
      ["utils", "blockchain", "makeDepositWarnings", "common", "line1Postfix"],
      t,
    )}`,
    ...byBlockchain[blockchain],
  ];
};

export const makeGasFeeDepositFormWarnings = (
  blockchain: Blockchain,
  t: TFunction,
  isTestnet: boolean,
) => {
  const { label: blockchainLabel } = getBlockchainItemBy(blockchain, t);
  const byBlockchain: Record<Blockchain, string[]> = {
    [Blockchain.Ethereum]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.EthereumV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Klaytn]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.KlaytnV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Baobab]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Polygon]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Amoy]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.PolygonV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.OverTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "overWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "overWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Over]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "overWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "overWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Bsc]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "bscWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "bscWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.WemixTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "wemixWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "wemixWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.XplaTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "xplaWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "xplaWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Xpla]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "xplaWarning",
          "line1Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositFormWarnings",
          "xplaWarning",
          "line1Postfix",
        ],
        t,
      )}`,
    ],
    [Blockchain.Bitcoin]: [],
  };
  return [
    `${translate(
      [
        "utils",
        "blockchain",
        "makeGasFeeDepositFormWarnings",
        "common",
        "line1Prefix",
      ],
      t,
    )}${prefixTestnet(blockchainLabel, isTestnet)}${translate(
      [
        "utils",
        "blockchain",
        "makeGasFeeDepositFormWarnings",
        "common",
        "line1Postfix",
      ],
      t,
    )}`,
    ...byBlockchain[blockchain],
    translate(
      [
        "utils",
        "blockchain",
        "makeGasFeeDepositFormWarnings",
        "common",
        "line2",
      ],
      t,
    ),
  ];
};

export const makeGasFeeDepositWarnings = (
  blockchain: Blockchain,
  t: TFunction,
  isTestnet: boolean,
) => {
  const { label } = getBlockchainItemBy(blockchain, t);
  const blockchainLabel = prefixTestnet(label, isTestnet);
  const byBlockchain: Record<Blockchain, string[]> = {
    [Blockchain.Ethereum]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line2Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line2Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line3",
        ],
        t,
      ),
    ],
    [Blockchain.EthereumV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line2Prefix",
        ],
        t,
      )}${confirmCountText(blockchain)}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line2Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "ethWarning",
          "line3",
        ],
        t,
      ),
    ],
    [Blockchain.Klaytn]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.KlaytnV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Baobab]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "klaytnWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Polygon]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Amoy]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.PolygonV2]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "polygonWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.OverTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Over]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "overWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Bsc]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "bscWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "bscWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "bscWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.WemixTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "wemixWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "wemixWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "wemixWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.XplaTestnet]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Xpla]: [
      `${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line1Prefix",
        ],
        t,
      )}${blockchainLabel}${translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line1Postfix",
        ],
        t,
      )}`,
      translate(
        [
          "utils",
          "blockchain",
          "makeGasFeeDepositWarnings",
          "xplaWarning",
          "line2",
        ],
        t,
      ),
    ],
    [Blockchain.Bitcoin]: [],
  };
  return byBlockchain[blockchain];
};

export const walletNameByBlockchain = ({
  blockchain,
  t,
}: {
  blockchain: Blockchain;
  t: TFunction;
}) => {
  return blockchain === Blockchain.Bitcoin
    ? translate(["utils", "blockchain", "walletNameByBlockchain", "wallet"], t)
    : translate(
        ["utils", "blockchain", "walletNameByBlockchain", "masterWallet"],
        t,
      );
};

export const getBlockchainsByLanguage = () => {
  const koBlockchains = [
    Blockchain.Bitcoin,
    Blockchain.Ethereum,
    Blockchain.EthereumV2,
    Blockchain.Klaytn,
    Blockchain.KlaytnV2,
    Blockchain.Baobab,
    Blockchain.Polygon,
    Blockchain.Amoy,
    Blockchain.PolygonV2,
    Blockchain.OverTestnet,
    Blockchain.Over,
    Blockchain.Bsc,
    Blockchain.WemixTestnet,
    Blockchain.XplaTestnet,
    Blockchain.Xpla,
  ];
  return koBlockchains;
};

export const checkAddressFormat = (address: string, blockchain: Blockchain) => {
  const isEvm = ![Blockchain.Bitcoin].includes(blockchain);

  if (isEvm) {
    if (!address.startsWith("0x")) {
      return "주소 형식은 0x로 시작해야 합니다.";
    } else if (!/^0x[0-9a-fA-F]*$/.exec(address)) {
      return "유효하지 않은 문자를 입력했습니다.";
    } else if (address.length != 42) {
      return "주소의 길이가 맞지 않습니다.";
    }
  }
  // TODO: Non-EVM 추가 시 로직 추가
  return "";
};
