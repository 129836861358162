import _ from "lodash";
import { Instance, flow, types } from "mobx-state-tree";
import { Blockchain, SupportedBlockchainDto } from "src/__generate__";
import { getSupportedBlockchains } from "src/apis/blockchains";

const Blockchains = types
  .model("Blockchains", {
    data: types.optional(
      types.array(types.frozen<SupportedBlockchainDto>()),
      [],
    ),
  })
  .views((self) => ({
    get blockchains() {
      // bitcoin 제외한 v1 체인 맨 아래에 오도록 배치, bitcoin v2 적용하면서 제거 예정
      let bitcoin: Blockchain | undefined;
      const array = _.orderBy(
        Array.from(self.data.values()),
        ["version", "blockchain", "symbol"],
        ["desc", "asc", "asc"],
      )
        .filter((x) => {
          if (x.blockchain === Blockchain.Bitcoin) {
            bitcoin = x.blockchain;
          }
          return typeof x !== "undefined" && x.blockchain !== Blockchain.Bitcoin
        })
        .map((x) => x?.blockchain as Blockchain);
      return bitcoin === Blockchain.Bitcoin ? [bitcoin, ...array] : array;
    },
  }))
  .actions((self) => {
    const fetch = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getSupportedBlockchains> =
        yield getSupportedBlockchains();

      self.data.replace(response);
    });

    const checkV2Chain = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.version === 2,
        ),
      );
    };
    const checkTestnet = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.isTestnet,
        ),
      );
    };

    return {
      fetch,
      checkV2Chain,
      checkTestnet,
    };
  });

export type IBlockchains = Instance<typeof Blockchains>;
export default Blockchains;
