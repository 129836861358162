import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#FF2F00"/>
      <path d="M17.1239 5.85293C15.7651 4.71158 14.0542 4.05636 12.2799 4L8.2034 14.7794L17.1239 5.85293Z"
            fill="white"/>
      <path d="M18.1448 6.87451L13.0192 12.0035L18.1448 17.1325C20.6161 14.1594 20.6161 9.84061 18.1448 6.87451Z"
            fill="white"/>
      <path d="M10.2804 5.23294L4.0142 11.4963C3.88747 13.5112 4.52113 15.498 5.79549 17.0621L10.2804 5.23294Z"
            fill="white"/>
      <path d="M11.9984 13.0181L6.87274 18.1471C9.84391 20.62 14.1528 20.62 17.124 18.1471L11.9984 13.0181Z"
            fill="white"/>
    </svg>
  );
}

export default SvgComponent;
